import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
import { getUserByToken } from "../../crud/auth.crud";
import * as routerHelpers from "../../router/RouterHelpers";

export const actionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action",
  UserRequested: "[Request User] Action",
  UserLoaded: "[Load User] Auth API",
  DrivingSchoolSelected: "[Select Driving School] Action",
  RefreshToken: "[Refresh Token] Action"
};

const initialAuthState = {
  user: undefined,
  authToken: undefined,
  drivingSchool: undefined
};

export const reducer = persistReducer(
    { storage, key: "cassandra-auth", whitelist: ["user", "authToken", "drivingSchool"] },
    (state = initialAuthState, action) => {
      switch (action.type) {
        case actionTypes.Login: {
          const { authToken } = action.payload;

          return { authToken, user: undefined, drivingSchool: undefined };
        }

        case actionTypes.Logout: {
          routerHelpers.forgotLastLocation();
          return initialAuthState;
        }

        case actionTypes.UserLoaded: {
          const { user } = action.payload;

          return { ...state, user };
        }

        case actionTypes.DrivingSchoolSelected: {
          const { drivingSchool } = action.payload;

          return { ...state, drivingSchool };
        }

        case actionTypes.RefreshToken: {
          const { authToken } = action.payload;

          return { authToken, user: undefined, drivingSchool: undefined };
        }

        default:
          return state;
      }
    }
);

export const actions = {
  login: authToken => ({ type: actionTypes.Login, payload: { authToken } }),
  logout: () => ({ type: actionTypes.Logout }),
  requestUser: user => ({ type: actionTypes.UserRequested, payload: { user } }),
  fulfillUser: user => ({ type: actionTypes.UserLoaded, payload: { user } }),
  selectDrivingSchool: drivingSchool => ({ type: actionTypes.DrivingSchoolSelected, payload: { drivingSchool }}),
  refreshtoken: authToken => ({ type: actionTypes.RefreshToken, payload: { authToken } })
};

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    const { data: user } = yield getUserByToken();

    yield put(actions.fulfillUser(user[0]));
  });

  yield takeLatest(actionTypes.RefreshToken, function* refreshToken() {
    yield put(actions.requestUser());
  })
}
