import React from "react";
import { connect } from "react-redux";
import { Button } from "react-bootstrap";
import { withStyles, makeStyles, Grid, Table, TableBody, TableCell, TableHead, TableRow, Paper, Card, CardContent, CardActions, Typography, CircularProgress, TableFooter, colors, IconButton, Snackbar, SnackbarContent, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded';
import { FiscalPrint } from '../../../fp_epson/fiscalprint.js'
import GuidaevaiService from '../../api/GuidaevaiService'
import NewServiceButton from './NewServiceButton'
import SetIpAddressDialog from './SetIpAddressDialog';
import clsx from 'clsx';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import WarningIcon from '@material-ui/icons/Warning';
import PropTypes from 'prop-types';


const StyledTableCell = withStyles(theme => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
    footer: {
        fontSize: 14,
        fontWeight: "bold",
    }
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
    root: {
        '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.background.default,
        },
    },
}))(TableRow);

const useStyles = makeStyles(theme => ({
    gridRoot: {
        flexGrow: 1,
    },
    card: {
      minWidth: 275
    },
    title: {
        fontSize: 14,
    },
    root: {
        width: '100%',
        overflowX: 'auto',
    },
    table: {
        minWidth: 700,
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
}));

const variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon,
};

const useStyles1 = makeStyles(theme => ({
    success: {
        backgroundColor: colors.green[600],
    },
    error: {
        backgroundColor: theme.palette.error.dark,
    },
    info: {
        backgroundColor: theme.palette.primary.main,
    },
    warning: {
        backgroundColor: colors.amber[700],
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
}));

function MySnackbarContentWrapper(props) {
    const classes = useStyles1();
    const { className, message, onClose, variant, ...other } = props;
    const Icon = variantIcon[variant];
  
    return (
      <SnackbarContent
        className={clsx(classes[variant], className)}
        aria-describedby="client-snackbar"
        message={
          <span id="client-snackbar" className={classes.message}>
            <Icon className={clsx(classes.icon, classes.iconVariant)} />
            {message}
          </span>
        }
        action={[
          <IconButton key="close" aria-label="Close" color="inherit" onClick={onClose}>
            <CloseIcon className={classes.icon} />
          </IconButton>,
        ]}
        {...other}
      />
    );
}

MySnackbarContentWrapper.propTypes = {
    className: PropTypes.string,
    message: PropTypes.node,
    onClose: PropTypes.func,
    variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired,
};
  

class CashDeskPage extends React.Component {
    classes = useStyles
    guidaevaiService = new GuidaevaiService()
    drivingSchool = null
    ipAddress = ''
    transaction = {}
    departmentType = ['Esente IVA', 'IVA 22%', 'Esclusa art. 15']

    createData(product, price) {
        return { product, price};
    }

    constructor(props) {
        super(props);
        const { drivingSchool } = this.props
        this.state = {
            isLoading: true,
            printerStatus: '',
            isPrinterOk: false,
            services: [],
            productsContainer: [],
            service: '',
            product: '',
            price: '',
            vat: true,
            department: 2,
            totalPrice: 0,
            totalVat: 0,
            products: '',
            code: '',
            openSnackbar: false,
            showSuccess: false,
            messageSuccess: 'scontrino emesso',
            showError: false,
            isFiscalLoading: false,
            isClosureLoading: false,
            openAlert: false,
            openLottery: false,
            lotteryEnabled: false
        }
        this.productsContainer = []
        this.products = []
        this.drivingSchool = drivingSchool
        this.getServices = this.getServices.bind(this)
        this.setDepartments = this.setDepartments.bind(this)
        this.getPrinterStatus = this.getPrinterStatus.bind(this)
        this.handleCloseAlert = this.handleCloseAlert.bind(this)
        this.handleCloseLottery = this.handleCloseLottery.bind(this)
        this.getPrinterStatus()
        this.transaction.owner = drivingSchool
        this.transaction.transactionservice_set = []
    }

    componentDidMount() {
        this.getServices()
        const today = new Date()
        const lotteryStartDate = new Date('2021-02-01')
        if (today >= lotteryStartDate){
            if (today.toLocaleDateString() === lotteryStartDate.toLocaleDateString()) {
                this.setState({openLottery: true})
            }
            this.setState({lotteryEnabled: true})
        }
    }

    getServices() {
        this.guidaevaiService.getServices(this.drivingSchool.id).then(services => {
            this.setState({
                isLoading: false,
                services: services.data,
                products: this.productsContainer
            });
        });
    }

    setDepartments() {
        let epos_status = new FiscalPrint.fiscalPrint()
        let xmlHTTPRequestURLMain = "https://" + this.ipAddress + "/cgi-bin/fpmate.cgi";
        let data_to_send =  '<printerCommand><directIO command="4002" data="03ESCLUSA ART 15      0000000000000000000000000000100000000000000  "/></printerCommand>';
        epos_status.onreceive = function (res, tag_list_names, add_info_version) {
            console.log(res)
            console.log(add_info_version)
        }
        epos_status.onerror = function(res) {
            console.log(res)
        }
        epos_status.send(xmlHTTPRequestURLMain, data_to_send)
    }

    getPrinterStatus() {
        const { ipAddress } = this.props
        if (ipAddress) {
            this.ipAddress = ipAddress
            this.setDepartments()
            let epos_status = new FiscalPrint.fiscalPrint()
            let xmlHTTPRequestURLMain = "https://" + this.ipAddress + "/cgi-bin/fpmate.cgi";
            let data_to_send =  '<printerCommand>' +
                                '   <queryPrinterStatus statusType="1" />' +
                                '</printerCommand>';
            epos_status.onreceive = function (res, tag_list_names, add_info) {
                if (add_info.rtMainStatus == '02' && add_info.rtSubStatus == '08') {
                    let epos_status_2 = new FiscalPrint.fiscalPrint()
                    let data_to_send_2 =  '<printerCommand>' +
                                            '   <queryPrinterStatus />' +
                                            '</printerCommand>';
                    epos_status_2.onreceive = function (res, tag_list_names, add_info) {
                        let message = ''
                        switch(add_info.fpStatus.substring(0,1))
                        {
                            case "0":
                                message = 'OK'
                                break;
                            case "2":
                                message = "Carta in esaurimento";
                                break;
                            case "3":
                                message = "Offline (fine carta o coperchio aperto)";
                                break;
                            default:
                                message = "Risposta errata";
                        }
                        this.setState({
                            printerStatus: message,
                            isPrinterOk: true
                        })
                    }.bind(this)
                    epos_status_2.onerror = function(res) {
                        console.log(res)
                    }.bind(this)
                    epos_status_2.send(xmlHTTPRequestURLMain, data_to_send_2)
                } else {
                    let status = ''
                    switch (add_info.rtSubStatus) {
                        case '05':
                            status = 'Da Censire';
                            break;
                        case '06':
                            status = 'Censito';
                            break;
                        case '07':
                            status = 'Attivato';
                            break;
                        default:
                            status = 'RT non attivo'
                            break
                    }
                    this.setState({
                        printerStatus: status,
                        isPrinterOk: false,
                        openAlert: true
                    })
                }
                console.log(res)
                console.log(add_info)
            }.bind(this)
            epos_status.onerror = function(res) {
                this.setState({
                    printerStatus: 'Errore di rete',
                    isPrinterOk: false
                })
                if (this.drivingSchool.id === 17){
                    this.setState({
                        printerStatus: 'OK',
                        isPrinterOk: true
                    })
                }
            }.bind(this)
            epos_status.send(xmlHTTPRequestURLMain, data_to_send)
        }
    }

    setProduct(serviceId) {
        let service = this.state.services.filter(service => { return service.id === serviceId })[0]
        this.setState({service: service, product: service.name})
    }

    setPrice(n) {
        if (this.state.price.split('.')[1] && this.state.price.split('.')[1].length >= 2) {
            return
        }
        if ((n === '0' && this.state.price !== '') || n !== '0') {
            this.setState({price: this.state.price + n})
        }
    }

    setDot() {
        if (this.state.price !== '' && this.state.price.search(/\./) < 0) {
            this.setState({price: this.state.price + '.'})
        }
    }

    setVat() {
        this.setState({vat: !this.state.vat})
    }

    setDepartment(department) {
        switch (department) {
            case 1:
                this.setState({vat: false, department: 1})
                break;
            case 2:
                this.setState({vat: true, department: 2})
                break;
            case 3:
                this.setState({vat: false, department: 3})
                break;
            default:
                break;
        }
    }

    clearPrice(type) {
        if (type === 0) {
            this.setState({price: ''})
        } else {
            this.setState({price: this.state.price.slice(0, -1)})
        }
    }

    addProduct() {
        let vat = 0
        if (this.state.vat) {
            vat = (this.state.price/1.22)*(22/100)
        }
        this.transaction.transactionservice_set.push({service: this.state.service, vat_price: parseInt(vat * 100), price: parseFloat(this.state.price * 100)})
        this.products.push('<printRecItem description="' + this.state.product + '" quantity="1" unitPrice="' + this.state.price + '" department="' + this.state.department + '" justification="1" />')
        this.productsContainer.push({
            name: this.state.product,
            vat: this.state.vat,
            price: this.state.price
        })
        this.setState({
            productsContainer: this.productsContainer,
            products: this.products,
            totalPrice: this.state.totalPrice + parseFloat(this.state.price),
            totalVat: this.state.totalVat + vat,
            product: '',
            price: ''
        })
    }

    deleteProduct(i) {
        let vat = 0
        if (this.productsContainer[i].vat) {
           vat = (this.productsContainer[i].price/1.22)*(22/100) 
        }
        this.setState({
            totalPrice: this.state.totalPrice - parseFloat(this.productsContainer[i].price),
            totalVat: this.state.totalVat - vat
        })
        this.transaction.transactionservice_set.splice(i,1)
        this.productsContainer.splice(i,1)
        this.products.splice(i,1)
        this.setState({
            productsContainer: this.productsContainer,
            products: this.products
        })
    }

    sendFiscalReceipt() {
        this.setState({isFiscalLoading: true})
        let epos = new FiscalPrint.fiscalPrint()
        let xmlHTTPRequestURLMain = "https://" + this.ipAddress + "/cgi-bin/fpmate.cgi";
        let data_to_send = "";
        data_to_send = data_to_send + '<printerFiscalReceipt><beginFiscalReceipt />';
        data_to_send = data_to_send + this.state.products.join("");
        // data_to_send = data_to_send + '<directIO command="1078" data="1070000"/>';
        if (this.state.code !== '') {
            // data_to_send = data_to_send + '<directIO command="1135" data="01' + this.state.code + '        0000" />'
            data_to_send = data_to_send + '<printRecLotteryID lotteryID="'+this.state.code+'" />';
        }
        data_to_send = data_to_send + '<printRecTotal description="PAGAMENTO" payment="0" paymentType="0" index="0" justification = "1" />';
        data_to_send = data_to_send + '<printRecMessage messageType="3" index="1" font="4" message="Arrivederci e Grazie" />';
        data_to_send = data_to_send + '<endFiscalReceipt /></printerFiscalReceipt>';
        console.log(data_to_send)
        epos.onreceive = function (result, tag_names_array, add_info) {
            if (result.success) {
                this.transaction.total_vat_price = parseInt(this.state.totalVat * 100)
                this.transaction.total_price = this.state.totalPrice * 100
                this.transaction.fiscal_receipt_number = add_info.fiscalReceiptNumber
                this.transaction.z_report_number = add_info.zRepNumber
                this.guidaevaiService.addTransaction(this.transaction).then(data => {
                    this.transaction = {
                        owner: this.drivingSchool,
                        transactionservice_set: []
                    }
                    this.products = []
                    this.productsContainer = []
                    this.setState({
                        productsContainer: [],
                        products: [],
                        totalPrice: 0,
                        totalVat: 0,
                        showSuccess: true,
                        messageSuccess: 'Scontrino emesso.',
                        isFiscalLoading: false,
                        showError: false
                    })
                })
            } else {
                this.setState({
                    showError: true,
                    isFiscalLoading: false,
                    showSuccess: false
                })
            }
        }.bind(this)
        // alert (data_to_send);
        epos.send(xmlHTTPRequestURLMain, data_to_send);
    }

    sendDailyClosure() {
        this.setState({isClosureLoading: true})
        let epos = new FiscalPrint.fiscalPrint()
        let xmlHTTPRequestURLMain = "https://" + this.ipAddress + "/cgi-bin/fpmate.cgi";
        let data_to_send = '<printerFiscalReport><displayText data="Chiusura giornaliera"/><printZReport timeout="0" /></printerFiscalReport>';
        epos.onreceive = function (result, tag_names_array, add_info) {
            console.log(result)
            if (result.success) {
                this.setState({
                    showSuccess: true,
                    messageSuccess: 'Chiusura effettuata.',
                    isClosureLoading: false,
                    showError: false
                })
            } else {
                this.setState({
                    showError: true,
                    isClosureLoading: false,
                    showSuccess: false
                })
            }
        }.bind(this)
        // alert (data_to_send);
        epos.send(xmlHTTPRequestURLMain, data_to_send);
    }

    handleClose(event, reason) {
        if (reason === 'clickaway') {
          return;
        }
    
        this.setState({openSnackbar: false});
    }

    handleCloseAlert() {
        this.setState({openAlert: false})
    }

    handleCloseLottery() {
        this.setState({openLottery: false})
    }

    handleClosureLottery() {
        this.sendDailyClosure()
        this.handleCloseLottery()
    }

    handleCloseSnackbar() {
        this.setState({
            showSuccess: false,
            showError: false,
            messageSuccess: ''
        })
    }

    render() {
        if (this.state.isLoading) {
            return ( <CircularProgress /> )
        }

        let servicesContainer = this.state.services.map(service => {
            return (
                <Grid item xs={4} md={12} key={service.id}>
                    <Paper className={this.classes.root}>
                        <Button variant="secondary" size="lg" block onClick={() => this.setProduct(service.id)} style={{textAlign: "left", height: "100%"}}>{service.name}</Button>
                    </Paper>
                </Grid>
            )
        })

        return ( 
            <>
                <Grid container className={this.classes.root} spacing={2}>
                    <Grid item xs={12} sm={12} md={4}>
                        <Grid container className={this.classes.root} spacing={1} >
                            {servicesContainer}
                        </Grid>
                        <NewServiceButton getServices={this.getServices}/>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Grid container className={this.classes.root} spacing={2}>
                            <Grid item xs={12}>
                                <Card className={this.classes.card} key="-1">
                                    <CardContent>
                                        <Table>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell>Servizio</TableCell>
                                                    <TableCell align="right">{this.state.product}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>Prezzo</TableCell>
                                                    <TableCell align="right">{this.state.price === '' ? '0' : this.state.price}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>IVA</TableCell>
                                                    <TableCell align="right">{this.departmentType[this.state.department - 1]}</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </CardContent>
                                </Card>
                                <Button variant="primary" size="lg" block className={this.classes.button} onClick={() => this.addProduct()} disabled={!this.state.product || !this.state.price}>Aggiungi allo Scontrino</Button>
                            </Grid>
                        </Grid>
                        <Grid container className={this.classes.root} spacing={2}>
                            <Grid item xs={12}>
                                <Paper className={this.classes.root} style={{padding: 10}}>
                                    <Grid container className={this.classes.root} spacing={1}>
                                        <Grid item xs={4}>
                                            <Button variant="secondary" size="lg" block className={this.classes.button} style={{height: "100%"}} onClick={() => this.setDepartment(2)}>IVA 22%</Button>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Button variant="secondary" size="lg" block className={this.classes.button} style={{height: "100%"}} onClick={() => this.setDepartment(1)}>Esente IVA</Button>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Button variant="secondary" size="lg" block className={this.classes.button} style={{height: "100%"}} onClick={() => this.setDepartment(3)}>Esclusa art. 15</Button>
                                        </Grid>
                                        {/* <Grid item xs={4}>
                                            <Button variant="secondary" size="lg" block className={this.classes.button} style={{height: "100%"}} onClick={() => this.setVat()}>{this.state.vat ? 'Esente IVA' : 'IVA 22%'}</Button>
                                        </Grid> */}
                                        <Grid item xs={6}>
                                            <Button variant="secondary" size="lg" block className={this.classes.button} style={{height: "100%"}} onClick={() => this.clearPrice(0)}>CE</Button>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Button variant="secondary" size="lg" block className={this.classes.button} style={{height: "100%"}} onClick={() => this.clearPrice(1)}>C</Button>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Button variant="secondary" size="lg" block className={this.classes.button} style={{height: "100%"}} onClick={() => this.setPrice('1')}>1</Button>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Button variant="secondary" size="lg" block className={this.classes.button} style={{height: "100%"}} onClick={() => this.setPrice('2')}>2</Button>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Button variant="secondary" size="lg" block className={this.classes.button} style={{height: "100%"}} onClick={() => this.setPrice('3')}>3</Button>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Button variant="secondary" size="lg" block className={this.classes.button} style={{height: "100%"}} onClick={() => this.setPrice('4')}>4</Button>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Button variant="secondary" size="lg" block className={this.classes.button} style={{height: "100%"}} onClick={() => this.setPrice('5')}>5</Button>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Button variant="secondary" size="lg" block className={this.classes.button} style={{height: "100%"}} onClick={() => this.setPrice('6')}>6</Button>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Button variant="secondary" size="lg" block className={this.classes.button} style={{height: "100%"}} onClick={() => this.setPrice('7')}>7</Button>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Button variant="secondary" size="lg" block className={this.classes.button} style={{height: "100%"}} onClick={() => this.setPrice('8')}>8</Button>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Button variant="secondary" size="lg" block className={this.classes.button} style={{height: "100%"}} onClick={() => this.setPrice('9')}>9</Button>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Button variant="secondary" size="lg" block className={this.classes.button} style={{height: "100%"}} onClick={() => this.setPrice('0')}>0</Button>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Button variant="secondary" size="lg" block className={this.classes.button} style={{height: "100%"}} onClick={() => this.setDot()}>.</Button>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Grid container className={this.classes.root} spacing={2}>
                            <Grid item xs={12}>
                                <Card className={this.classes.card}>
                                    <CardContent>
                                        <Table className={this.classes.table}>
                                            <TableBody>
                                                <TableRow>
                                                    <StyledTableCell>Stato stampante:</StyledTableCell>
                                                    <StyledTableCell align="right">{this.state.printerStatus}</StyledTableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </CardContent>
                                    <CardActions style={{justifyContent: "center"}}>
                                        <SetIpAddressDialog getPrinterStatus={this.getPrinterStatus}/>
                                    </CardActions>
                                </Card>
                            </Grid>
                        </Grid>
                        <Grid container className={this.classes.root} spacing={2}>
                            <Grid item xs={12}>
                                <Paper className={this.classes.root}>
                                    <Table className={this.classes.table}>
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell></StyledTableCell>
                                                <StyledTableCell>Servizio</StyledTableCell>
                                                <StyledTableCell align="right">IVA</StyledTableCell>
                                                <StyledTableCell align="right">Prezzo (€)</StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.productsContainer.map((product, i) => {
                                                return <StyledTableRow key={i + 1}>
                                                    <StyledTableCell><DeleteForeverRoundedIcon className={this.classes.icon} onClick={() => this.deleteProduct(i)} /></StyledTableCell>
                                                    <StyledTableCell>{product.name}</StyledTableCell>
                                                    <StyledTableCell align="right">{product.vat ? '22%' : '0%'}</StyledTableCell>
                                                    <StyledTableCell align="right">{parseFloat(product.price).toFixed(2)}</StyledTableCell>
                                                </StyledTableRow>
                                            })}
                                        </TableBody>
                                        <TableFooter>
                                            <TableRow>
                                                <StyledTableCell></StyledTableCell>
                                                <StyledTableCell colSpan={1}><b>Totale</b></StyledTableCell>
                                                <StyledTableCell align="right"></StyledTableCell>
                                                <StyledTableCell align="right"><b>{this.state.totalPrice.toFixed(2)}</b></StyledTableCell>
                                            </TableRow>
                                            <TableRow>
                                                <StyledTableCell></StyledTableCell>
                                                <StyledTableCell colSpan={1}>Di cui IVA</StyledTableCell>
                                                <StyledTableCell align="right"></StyledTableCell>
                                                <StyledTableCell align="right">{this.state.totalVat.toFixed(2)}</StyledTableCell>
                                            </TableRow>
                                            {this.state.lotteryEnabled ? (
                                                <TableRow>
                                                    <TableCell colSpan={4}>
                                                        <TextField
                                                            id="standard-name"
                                                            label="Codice Lotteria"
                                                            className={this.classes.textField}
                                                            value={this.state.code}
                                                            onChange={(e) => this.setState({code: e.target.value})}
                                                            margin="normal"
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            ) : '' }
                                        </TableFooter>
                                    </Table>
                                </Paper>
                                <Button variant="primary" size="lg" block className={this.classes.button} onClick={() => this.sendFiscalReceipt()} disabled={this.state.totalPrice <= 0 || !this.state.isPrinterOk || this.state.isFiscalLoading}>Emetti Scontrino{this.state.isFiscalLoading ? <CircularProgress style={{color: '#fff', marginLeft: 16}} size={20}/> : ''}</Button>
                            </Grid>
                        </Grid>
                        <Grid container className={this.classes.root} spacing={2}>
                            <Grid item xs={12}>
                                <Button variant="primary_low" size="lg" block className={this.classes.button} onClick={() => this.sendDailyClosure()} disabled={!this.state.isPrinterOk || this.state.isClosureLoading}>Chiusura Giornaliera{this.state.isClosureLoading ? <CircularProgress style={{color: '#fff', marginLeft: 16}} size={20}/> : ''}</Button>
                            </Grid>
                        </Grid>
                        <Grid container className={this.classes.root} spacing={2}>
                            <Grid item xs={12}>
                                {this.state.showSuccess ? <MySnackbarContentWrapper
                                    variant="success"
                                    onClose={() => this.handleCloseSnackbar()}
                                    message={this.state.messageSuccess}
                                /> : ''}
                                {this.state.showError ? <MySnackbarContentWrapper
                                    variant="error"
                                    onClose={() => this.handleCloseSnackbar()}
                                    message="Si è verificato un errore!"
                                /> : ''}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Dialog
                    open={this.state.openAlert}
                    onClose={this.handleCloseAlert}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Stampante non attiva</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            La stampante fiscale è nello stato {this.state.printerStatus}. Per poter continuare ad usarla, contattare l'assistenza.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.handleCloseAlert()} color="primary">
                            Ok
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={this.state.openLottery}
                    onClose={this.handleCloseLottery}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Lotteria Attiva</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Da oggi sarà attiva la possibilità di inserire il codice lotteria del cliente. Per fare ciò è necessario effettuare una chiusura giornaliera prima di procedere con le transazioni.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.handleClosureLottery()} color="primary">
                            Chiusura Giornaliera
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }
}

const mapStateToProps = ({ auth: { drivingSchool }, fiscalPrinter: { ipAddress } }) => ({
    drivingSchool,
    ipAddress
});
  
export default connect(mapStateToProps)(CashDeskPage);