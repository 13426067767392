import axios from "axios";
import { BASE_URL } from "../crud/auth.crud";

class GuidaevaiService {

    async getDrivingSchools(drivingSchools) {
        return axios.get(BASE_URL + "api/cassandra-driving-schools/?driving_schools=" + drivingSchools)
    }
    
    async getServices(drivingSchoolId) {
        return axios.get(BASE_URL + "api/services/?driving_school=" + drivingSchoolId)
    }

    async addService(drivingSchoolId, name) {
        return axios.post(BASE_URL + "api/services/?driving_school=" + drivingSchoolId, {owner: drivingSchoolId, name: name})
    }

    async addTransaction(transaction) {
        let transactionservice_set = []
        transaction.transactionservice_set.forEach(transactionservice => {
            transactionservice_set.push(
                {
                    service: transactionservice.service.id,
                    vat_price: transactionservice.vat_price,
                    price: transactionservice.price
                }
            )
        })
        let body = {
            owner: transaction.owner.id,
            transactionservice_set: transactionservice_set,
            total_vat_price: transaction.total_vat_price,
            total_price: transaction.total_price,
            fiscal_receipt_number: transaction.fiscal_receipt_number,
            z_report_number: transaction.z_report_number
        }
        return axios.post(BASE_URL + "api/transactions/", body)
    }

    getTransactionStatistics(drivingSchoolId) {
        return axios.get(BASE_URL + "api/transactions/get_statistics/?driving_school=" + drivingSchoolId)
    }
}

export default GuidaevaiService;