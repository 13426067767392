/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { connect } from "react-redux";
import { Button } from "react-bootstrap";
import { TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid } from '@material-ui/core';
import GuidaevaiService from "../../../app/api/GuidaevaiService";

class NewServiceButton extends React.Component {
  guidaevaiService = new GuidaevaiService()

  constructor(props) {
    super(props)
    this.state = {
      open: false,
      name: ''
    }
  }

  openAddService() {
    this.setState({open: true});
  }

  closeAddService() {
    this.setState({open: false});
  }

  addService() {
    const { drivingSchool } = this.props
    this.guidaevaiService.addService(drivingSchool.id, this.state.name).then(() => {
        this.props.getServices()
      this.closeAddService()
    })
  }

  render() {
    return (
        <>
          <Grid container spacing={2}>
              <Grid item xs={12}>
                <Button
                    variant="primary_low"
                    size="lg"
                    block
                    onClick={() => this.openAddService()}
                >
                    Aggiungi nuovo servizio
                </Button>
              </Grid>
          </Grid>
            <Dialog open={this.state.open} onClose={() => this.closeAddService()} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Aggiungi un servizio</DialogTitle>
                <DialogContent>
                <DialogContentText>
                    Inserisci il nome del servizio che vuoi aggiungere
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    id="service"
                    label="Nome servizio"
                    type="text"
                    fullWidth
                    onChange={(e) => this.setState({name: e.target.value})}
                />
                </DialogContent>
                <DialogActions>
                <Button onClick={() => this.closeAddService()} color="primary_low">
                    Annulla
                </Button>
                <Button onClick={() => this.addService()} color="primary">
                    Aggiungi
                </Button>
                </DialogActions>
            </Dialog>
        </>
    );
  }
}

const mapStateToProps = store => ({
  drivingSchool: store.auth.drivingSchool
});

export default connect(mapStateToProps)(NewServiceButton);
