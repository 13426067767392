import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

export const actionTypes = {
  SetIpAddress: "[Set IP Address] Action",
};

const initialAuthState = {
  ipAddress: undefined,
};

export const reducer = persistReducer(
    { storage, key: "cassandra-fiscalPrinter", whitelist: ["ipAddress",] },
    (state = initialAuthState, action) => {
      switch (action.type) {
        case actionTypes.SetIpAddress: {
          const { ipAddress } = action.payload;

          return { ipAddress };
        }

        default:
          return state;
      }
    }
);

export const actions = {
  setIpAddress: ipAddress => ({ type: actionTypes.SetIpAddress, payload: { ipAddress } }),
};
