/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";

export default function ServicesSell(props) {
  return (
    <>
      <div className="kt-portlet kt-portlet--height-fluid">
        <div className="kt-portlet__head">
          <div className="kt-portlet__head-label">
            <h3 className="kt-portlet__head-title">Servizi Più Venduti</h3>
          </div>
        </div>
        <div className="kt-portlet__body">
          <div className="kt-widget4">
            {props.serviceTransactions.map(service => {
              return <div className="kt-widget4__item" key={service.id}>
                <span className="kt-widget4__title">{service.name}</span>
                <span className="kt-widget4__number kt-font-brand">{service.total_transaction}</span>
              </div>
            })}
          </div>
        </div>
      </div>
    </>
  );
}
