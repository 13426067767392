import React from "react";
import { connect } from "react-redux";
import { Grid, Card, CardActionArea, CardContent, CardMedia, Typography, CircularProgress } from '@material-ui/core';
import * as auth from "../../store/ducks/auth.duck";
import GuidaevaiService from '../../api/GuidaevaiService'


class DrivingSchoolChooser extends React.Component {
    guidaevaiService = new GuidaevaiService()

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            drivingSchools: [],
        }
    }

    componentDidMount() {
        const { user } = this.props
        this.getDrivingSchools(user.admin_drivingschool)
    }

    getDrivingSchools(drivingSchoolsId) {
        this.guidaevaiService.getDrivingSchools(drivingSchoolsId).then(drivingSchools => {
            drivingSchools = drivingSchools.data.filter(drivingSchool => {return drivingSchool.has_cassandra_enabled})
            if (drivingSchools.length === 1) {
                this.props.selectDrivingSchool(drivingSchools[0])
            } else {
                this.setState({
                    isLoading: false,
                    drivingSchools: drivingSchools,
                });                    
            }
        });
    }

    selectDrivingSchool(drivingSchool) {
        this.props.selectDrivingSchool(drivingSchool)
    }

    render() {
        if (this.state.isLoading) {
            return ( <CircularProgress /> )
        }

        return ( 
            <>
                <Grid container spacing={2}>
                    {this.state.drivingSchools.map(drivingSchool => {
                        return (
                            <Grid item xs={4} key={drivingSchool.id}>
                                <Card style={styles.card} onClick={() => this.selectDrivingSchool(drivingSchool)}>
                                    <CardActionArea>
                                        <CardMedia
                                        style={styles.media}
                                        image={drivingSchool.image}
                                        title={drivingSchool.name}
                                        />
                                        <CardContent>
                                            <Typography>
                                                {drivingSchool.name}
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Grid>
                        )
                    })}
                </Grid>
            </>
        );
    }
}

const mapStateToProps = ({ auth: { user, auth } }) => ({
    user
});
  
export default connect(mapStateToProps, auth.actions)(DrivingSchoolChooser);

const styles = {
    media: {
        height: 180,
    }
}