export default {
  header: {
    self: {},
    items: [
      {
        title: "Cassa",
        root: true,
        alignment: "left",
        page: "cash-desk"
      },
      {
        title: "Statistiche",
        root: true,
        alignment: "left",
        page: "dashboard",
      }
    ]
  },
  aside: {
    self: {},
    items: [
      {
        title: "Cassa",
        root: true,
        icon: "flaticon-squares-1",
        page: "cash-desk",
        bullet: "dot"
      },
      {
        title: "Statistiche",
        root: true,
        icon: "flaticon2-graphic",
        page: "dashboard",
        bullet: "dot"
      },
    ]
  }
};
