/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { connect } from "react-redux";
// import BreadCrumbs from "./components/BreadCrumbs";
import { TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, CircularProgress } from '@material-ui/core';
import { Alert } from "react-bootstrap";
import * as fiscalPrinter from "../../store/ducks/fiscalPrinter.duck";
import GuidaevaiService from "../../../app/api/GuidaevaiService";
import { FiscalPrint } from '../../../fp_epson/fiscalprint.js'
import Settings from '@material-ui/icons/Settings';


class SetIpAddressDialog extends React.Component {
  guidaevaiService = new GuidaevaiService()

  constructor(props) {
    super(props)
    this.state = {
      open: false,
      ipAddress: '',
      alert: false,
      alertMessage: '',
      loading: false
    }
  }

  componentDidMount() {
      const { ipAddress, drivingSchool } = this.props
      if (drivingSchool.id === 17) {
        this.props.setIpAddress('192.168.1.157')
        this.closeAddService()
        this.props.getPrinterStatus()
      } else {
        if (!ipAddress) {
          this.openAddService()
        } else {
          this.setState({ipAddress: ipAddress})
        }
      }
  }

  openAddService() {
    this.setState({open: true});
  }

  closeAddService() {
    this.setState({open: false});
  }

  addService() {
    this.setState({
      alert: false,
      alertMessage: '',
      loading: true,
    })
    if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(this.state.ipAddress)) {  
      let epos_status = new FiscalPrint.fiscalPrint()
      let xmlHTTPRequestURLMain = "https://" + this.state.ipAddress + "/cgi-bin/fpmate.cgi";
      let data_to_send =  '<printerCommand>' +
                          '   <queryPrinterStatus statusType="1" />' +
                          '</printerCommand>';
      epos_status.onreceive = function (res, tag_list_names, add_info_version) {
        this.setDepartments()
        this.setState({loading: false})
        this.props.setIpAddress(this.state.ipAddress)
        this.closeAddService()
        this.props.getPrinterStatus()
      }.bind(this)
      epos_status.onerror = function(res) {
        this.setState({
          alert: true,
          alertMessage: 'Registratore di cassa non trovato. Controllare che sia acceso e che l\'indirizzo inserito sia corretto.',
          loading: false
        })
      }.bind(this)
      epos_status.send(xmlHTTPRequestURLMain, data_to_send)
    } else {
      this.setState({
        alert: true,
        alertMessage: 'Inserire un indirizzo IP corretto.',
        loading: false
      })
    }
  }

  setDepartments() {
    let epos_status = new FiscalPrint.fiscalPrint()
    let xmlHTTPRequestURLMain = "https://" + this.state.ipAddress + "/cgi-bin/fpmate.cgi";
    let data_to_send =  '<printerCommand><directIO command="4005" data="012200"/></printerCommand>';
    epos_status.onreceive = function (res, tag_list_names, add_info_version) {
      console.log(res)
      console.log(add_info_version)
    }
    epos_status.onerror = function(res) {
      console.log(res)
    }
    epos_status.send(xmlHTTPRequestURLMain, data_to_send)

    data_to_send =  '<printerCommand><directIO command="4002" data="01ESENZIONE IVA       0000000000000000000000000000000000000000000  "/></printerCommand>';
    epos_status.onreceive = function (res, tag_list_names, add_info_version) {
      console.log(res)
      console.log(add_info_version)
    }
    epos_status.onerror = function(res) {
      console.log(res)
    }
    epos_status.send(xmlHTTPRequestURLMain, data_to_send)

    data_to_send =  '<printerCommand><directIO command="4002" data="02IVA 22              0000000000000000000000000000010000000000000  "/></printerCommand>';
    epos_status.onreceive = function (res, tag_list_names, add_info_version) {
      console.log(res)
      console.log(add_info_version)
    }
    epos_status.onerror = function(res) {
      console.log(res)
    }
    epos_status.send(xmlHTTPRequestURLMain, data_to_send)

    data_to_send =  '<printerCommand><directIO command="4002" data="03ESCLUSA ART 15      0000000000000000000000000000100000000000000  "/></printerCommand>';
    epos_status.onreceive = function (res, tag_list_names, add_info_version) {
      console.log(res)
      console.log(add_info_version)
    }
    epos_status.onerror = function(res) {
      console.log(res)
    }
    epos_status.send(xmlHTTPRequestURLMain, data_to_send)
  }

  render() {
    return (
      <>
        <Button onClick={() => this.openAddService()}><Settings />Configura indirizzo IP</Button>
        <Dialog open={this.state.open} onClose={() => this.closeAddService()} aria-labelledby="form-dialog-title" disableBackdropClick>
          <DialogTitle id="form-dialog-title">Configura indirizzo IP Registratore di Cassa</DialogTitle>
          <DialogContent>
            <DialogContentText>
                Inserisci il DHCP Address che trovi sullo scontrino di Configurazione
            </DialogContentText>
            {this.state.alert? <Alert variant="danger">{this.state.alertMessage}</Alert> : '' }
            <TextField
              autoFocus
              margin="dense"
              id="service"
              label="Indirizzo IP"
              type="text"
              fullWidth
              onChange={(e) => this.setState({ipAddress: e.target.value})}
              defaultValue={this.state.ipAddress}
            />
          </DialogContent>
          <DialogActions>
            {this.state.loading ? <CircularProgress size={16}/> : ''}
            <Button disabled={this.state.loading} onClick={() => this.addService()} color="primary">
                Configura
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

const mapStateToProps = ({ auth: { drivingSchool }, fiscalPrinter: { ipAddress } }) => ({
  drivingSchool,
  ipAddress
});
  
export default connect(mapStateToProps, fiscalPrinter.actions)(SetIpAddressDialog);