import React, { Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import Builder from "./Builder";
import Dashboard from "./Dashboard";
import DashboardOld from "./DashboardOld";
import CashDesk from "./CashDeskPage";
import { LayoutSplashScreen } from "../../../_metronic";
import DrivingSchoolChooser from "./DrivingSchoolChooserPage";


export default function HomePage() {
  const { isDrivingSchoolChosen } = useSelector(
      ({ auth }) => ({
          isDrivingSchoolChosen: auth.drivingSchool != null
      }),
      shallowEqual
  );

  if (!isDrivingSchoolChosen) {
    return (
      <Suspense fallback={<LayoutSplashScreen />}>
        <Switch>
            <DrivingSchoolChooser />
        </Switch>
      </Suspense>
    )
  } else {
    return (
      <Suspense fallback={<LayoutSplashScreen />}>
        <Switch>
          {
            /* Redirect from root URL to /cash-desk. */
            <Redirect exact from="/" to="/cash-desk" />
          }
          <Route path="/driving-schools" component={DrivingSchoolChooser} />
          <Route path="/cash-desk" component={CashDesk} />
          <Route path="/builder" component={Builder} />
          <Route path="/dashboard" component={Dashboard} />
          <Route path="/dashboard-old" component={DashboardOld} />
          <Redirect to="/error/error-v1" />
        </Switch>
      </Suspense>
    );
  }
}
