import axios from "axios";

export const BASE_URL = "https://app.guidaevai.com/";
export const client_id = "BKKBC52kzSLlNQaow7LdwNdSqfyn4eQQzP2c0HYr";
export const client_secret = "fXaHLH8RmDLMdmu1kZTc8rybU2bTjSLFZxQwowppQ75ctRAERPKpCu2O3otQEqX0pyMjtUfeuhB9FJQdqjxrhcVoJXUz0ISg1PfgZy8D5ll6PqR80xSPzs4aOANvmiDf";
export const grant_type = "password"

export const TOKEN_URL = BASE_URL + "o/token/";
export const ME_URL = BASE_URL + "api/users/";

export function login(client_id, client_secret, grant_type, username, password) {
  const formData = new FormData();
  formData.append('client_id', client_id)
  formData.append('client_secret', client_secret)
  formData.append('grant_type', grant_type)
  formData.append('username', username)
  formData.append('password', password)
  return axios.post(TOKEN_URL, formData);
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(ME_URL);
}
