import React from "react";
import { connect } from "react-redux";
import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar
} from "../../partials/content/Portlet";
import GuidaevaiService from "../../api/GuidaevaiService";
import { CircularProgress } from "@material-ui/core";
import TransactionsPricePerDayChart from "../../widgets/TransactionsPricePerDayChart";
import TransactionsPerDayChart from "../../widgets/TransactionsPerDayChart";
import ServicesPrice from "../../widgets/ServicesPrice";
import ServicesSell from "../../widgets/ServicesSell";
import moment from "moment";
import MonthlyVat from "../../widgets/MonthlyVat";

class Dashboard extends React.Component {
  guidaevaiService = new GuidaevaiService()
  drivingSchool = null

  constructor(props) {
    super(props);
    const { drivingSchool } = this.props
    this.state = {
      isLoading: true,
      statistics: [],
      days: [],
      total_prices: [],
      previous_total_prices: [],
      price_avarage: 0,
      price_percentage: 0,
      total_transactions: [],
      previous_total_transactions: [],
      transactions_avarage: 0,
      transactions_percentage: 0,
      monthly_vat: [],
      quarterly_vat: []
    }
    this.drivingSchool = drivingSchool
  }

  componentDidMount() {
    this.getTransactions()
  }

  getTransactions() {
    this.guidaevaiService.getTransactionStatistics(this.drivingSchool.id).then(statistics => {
      let dates = []
      let total_prices = []
      let previous_total_prices = []
      let total_price = 0
      let previous_total_price = 0
      let price_percentage = 0
      let total_transactions = []
      let previous_total_transactions = []
      let total_transaction = 0
      let previous_total_transaction = 0
      let transactions_percentage = 0
      let monthly_vat = []
      let quarterly_vat = []
      let total_vat = 0
      let found_daily = false
      let found_previous_daily = false
      for(let i = 30; i >= 0; i--) {
        let date = moment().subtract(i,'days')
        let date_formatted = date.format('YYYY-MM-DD')
        dates.push(date.format('DD MMM'))
        statistics.data.daily_transactions.forEach(daily_statistic => {
          if (!found_daily && daily_statistic.day === date_formatted) {
            found_daily = true
            total_prices.push((daily_statistic.total_price / 100).toFixed(2))
            total_price = total_price + daily_statistic.total_price
            total_transactions.push(daily_statistic.total_transaction)
            total_transaction = total_transaction + daily_statistic.total_transaction
          }
        });
        if (!found_daily) {
          total_prices.push(0)
          total_transactions.push(0)
        }
        found_daily = false
      }
      for(let i = 60; i > 30; i--) {
        let date = moment().subtract(i,'days')
        let date_formatted = date.format('YYYY-MM-DD')
        statistics.data.last_month_daily_transactions.forEach(previous_daily_statistic => {
          if (!found_previous_daily && previous_daily_statistic.day === date_formatted) {
            found_previous_daily = true
            previous_total_prices.push((previous_daily_statistic.total_price / 100).toFixed(2))
            previous_total_price = previous_total_price + previous_daily_statistic.total_price
            previous_total_transactions.push(previous_daily_statistic.total_transaction)
            previous_total_transaction = previous_total_transaction + previous_daily_statistic.total_transaction
          }
        });
        if (!found_previous_daily) {
          previous_total_prices.push(0)
          previous_total_transactions.push(0)
        }
        found_previous_daily = false
      }
      if (previous_total_price === 0) {
        price_percentage = 100
      } else {
        let difference = total_price - previous_total_price
        price_percentage = difference/previous_total_price*100
      }
      if (previous_total_transaction === 0) {
        transactions_percentage = 100
      } else {
        let difference = total_transaction - previous_total_transaction
        transactions_percentage = difference/previous_total_transaction*100
      }
      for(let i = 1; i <= 12; i++) {
        statistics.data.monthly_vat.forEach(monthly_statistic => {
          if (monthly_statistic.month === i) {
            monthly_vat.push((monthly_statistic.total_vat_price / 100).toFixed(2))
            total_vat = total_vat + monthly_statistic.total_vat_price
          } else {
            monthly_vat.push("0.00")
          }
          if (i%3===0) {
            quarterly_vat.push((total_vat/100).toFixed(2))
            total_vat=0
          }
        })
      }
      this.setState({
        isLoading: false,
        statistics: statistics.data,
        days: dates,
        total_prices: total_prices,
        previous_total_prices: previous_total_prices,
        price_avarage: (total_price/30/100).toFixed(2),
        price_percentage: price_percentage,
        total_transactions: total_transactions,
        previous_total_transactions: previous_total_transactions,
        transactions_avarage: (total_transaction/30).toFixed(2),
        transactions_percentage: transactions_percentage,
        monthly_vat: monthly_vat,
        quarterly_vat: quarterly_vat
      })
    })
  }

  render() {
    if (this.state.isLoading) {
      return ( <CircularProgress /> )
    }

    if (!this.drivingSchool.has_cassandra_statistics_enabled) {
      return ( <h2>Statistiche non abilitate</h2> )
    }
  
    return (
      <>
        <div className="row">
          <div className="col-xl-6">
            <Portlet fluidHeight={true}>
              <PortletHeader
                title="Entrate Giornaliere"
                toolbar={
                  <PortletHeaderToolbar />
                }
              />
  
              <PortletBody>
                <TransactionsPricePerDayChart
                  days={this.state.days}
                  total_prices={this.state.total_prices}
                  previous_total_prices={this.state.previous_total_prices}
                  price_avarage={this.state.price_avarage}
                  price_percentage={this.state.price_percentage}
                />
              </PortletBody>
            </Portlet>
          </div>
  
          <div className="col-xl-6">
            <Portlet fluidHeight={true}>
              <PortletHeader
                title="Scontrini Giornalieri"
                toolbar={
                  <PortletHeaderToolbar />
                }
              />
  
              <PortletBody>
                <TransactionsPerDayChart
                  days={this.state.days}
                  total_transactions={this.state.total_transactions}
                  previous_total_transactions={this.state.previous_total_transactions}
                  transactions_avarage={this.state.transactions_avarage}
                  transactions_percentage={this.state.transactions_percentage}
                />
              </PortletBody>
            </Portlet>
          </div>
        </div>
  
        <div className="row">
          <MonthlyVat monthly_vat={this.state.monthly_vat} quarterly_vat={this.state.quarterly_vat} />
        </div>
  
        <div className="row">
          <div className="col-xl-6">
            <ServicesPrice servicePrices={this.state.statistics.service_prices}/>
          </div>
          <div className="col-xl-6">
            <ServicesSell serviceTransactions={this.state.statistics.service_transactions}/>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ auth: { drivingSchool } }) => ({
  drivingSchool
});

export default connect(mapStateToProps)(Dashboard);