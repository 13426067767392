/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";

export default function MonthlyVat(props) {
  return (
    <>
        <div className="col-xl-3">
            <div className="kt-portlet kt-portlet--height-fluid">
                <div className="kt-portlet__head">
                    <div className="kt-portlet__head-label">
                        <h3 className="kt-portlet__head-title">IVA 1° Trimestre</h3>
                    </div>
                </div>
                <div className="kt-portlet__body">
                    <div className="kt-widget4">
                        <div className="kt-widget4__item">
                            <span className="kt-widget4__title">Gennaio</span>
                            <span className="kt-widget4__number kt-font-focus">{props.monthly_vat[0]}€</span>
                        </div>
                        <div className="kt-widget4__item">
                            <span className="kt-widget4__title">Febbraio</span>
                            <span className="kt-widget4__number kt-font-focus">{props.monthly_vat[1]}€</span>
                        </div>
                        <div className="kt-widget4__item">
                            <span className="kt-widget4__title">Marzo</span>
                            <span className="kt-widget4__number kt-font-focus">{props.monthly_vat[2]}€</span>
                        </div>
                        <div className="kt-widget4__item">
                            <span className="kt-widget4__title"><b>Totale</b></span>
                            <span className="kt-widget4__number kt-font-focus"><b>{props.quarterly_vat[0]}€</b></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="col-xl-3">
            <div className="kt-portlet kt-portlet--height-fluid">
                <div className="kt-portlet__head">
                    <div className="kt-portlet__head-label">
                        <h3 className="kt-portlet__head-title">IVA 2° Trimestre</h3>
                    </div>
                </div>
                <div className="kt-portlet__body">
                    <div className="kt-widget4">
                        <div className="kt-widget4__item">
                            <span className="kt-widget4__title">Aprile</span>
                            <span className="kt-widget4__number kt-font-focus">{props.monthly_vat[3]}€</span>
                        </div>
                        <div className="kt-widget4__item">
                            <span className="kt-widget4__title">Maggio</span>
                            <span className="kt-widget4__number kt-font-focus">{props.monthly_vat[4]}€</span>
                        </div>
                        <div className="kt-widget4__item">
                            <span className="kt-widget4__title">Giugno</span>
                            <span className="kt-widget4__number kt-font-focus">{props.monthly_vat[5]}€</span>
                        </div>
                        <div className="kt-widget4__item">
                            <span className="kt-widget4__title"><b>Totale</b></span>
                            <span className="kt-widget4__number kt-font-focus"><b>{props.quarterly_vat[1]}€</b></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="col-xl-3">
            <div className="kt-portlet kt-portlet--height-fluid">
                <div className="kt-portlet__head">
                    <div className="kt-portlet__head-label">
                        <h3 className="kt-portlet__head-title">IVA 3° Trimestre</h3>
                    </div>
                </div>
                <div className="kt-portlet__body">
                    <div className="kt-widget4">
                        <div className="kt-widget4__item">
                            <span className="kt-widget4__title">Luglio</span>
                            <span className="kt-widget4__number kt-font-focus">{props.monthly_vat[6]}€</span>
                        </div>
                        <div className="kt-widget4__item">
                            <span className="kt-widget4__title">Agosto</span>
                            <span className="kt-widget4__number kt-font-focus">{props.monthly_vat[7]}€</span>
                        </div>
                        <div className="kt-widget4__item">
                            <span className="kt-widget4__title">Settembre</span>
                            <span className="kt-widget4__number kt-font-focus">{props.monthly_vat[8]}€</span>
                        </div>
                        <div className="kt-widget4__item">
                            <span className="kt-widget4__title"><b>Totale</b></span>
                            <span className="kt-widget4__number kt-font-focus"><b>{props.quarterly_vat[2]}€</b></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="col-xl-3">
            <div className="kt-portlet kt-portlet--height-fluid">
                <div className="kt-portlet__head">
                    <div className="kt-portlet__head-label">
                        <h3 className="kt-portlet__head-title">IVA 4° Trimestre</h3>
                    </div>
                </div>
                <div className="kt-portlet__body">
                    <div className="kt-widget4">
                        <div className="kt-widget4__item">
                            <span className="kt-widget4__title">Ottobre</span>
                            <span className="kt-widget4__number kt-font-focus">{props.monthly_vat[9]}€</span>
                        </div>
                        <div className="kt-widget4__item">
                            <span className="kt-widget4__title">Novembre</span>
                            <span className="kt-widget4__number kt-font-focus">{props.monthly_vat[10]}€</span>
                        </div>
                        <div className="kt-widget4__item">
                            <span className="kt-widget4__title">Dicembre</span>
                            <span className="kt-widget4__number kt-font-focus">{props.monthly_vat[11]}€</span>
                        </div>
                        <div className="kt-widget4__item">
                            <span className="kt-widget4__title"><b>Totale</b></span>
                            <span className="kt-widget4__number kt-font-focus"><b>{props.quarterly_vat[3]}€</b></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  );
}
